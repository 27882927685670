<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="currencyMasterValidation">
      <b-form>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.go(-1)"
            >
            <feather-icon icon="SkipBackIcon" />  {{ $t("Common.Back") }}
            </b-button>
          </b-col>
        </b-row> 
        <b-row class="mt-2">
          <b-col md="6">
            <b-form-group :label="$t('Currency Master List.Currency Name')">
              <validation-provider
                #default="{ errors }"
                name="Currency Name"
                rules="required"
              >
                <b-form-input
                  v-model="form.currency_name"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Currency Master List.Currency Name')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group :label="$t('Currency Master List.Currency Code')">
              <validation-provider
                #default="{ errors }"
                name="Currency Code"
                rules="required"
              >
                <b-form-input
                  v-model="form.currency_code"
                  :state="errors.length > 0 ? false : null"
                  type="text"
                  :placeholder="$t('Currency Master List.Currency Code')"
                  :disabled="!$can('write', 'masters')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group :label="$t('Currency Master List.Country Name')">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <v-select 
                  :reduce="(country) => country.country_id"
                  label="country_name"
                  :placeholder="$t('Currency Master List.Country Name')"
                  :disabled="!$can('write', 'masters')"
                  v-model="form.currency_country_id"
                  :options="$store.getters['masters/getAllCountry']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> 
          </b-col>
          <b-col cols="12" class="text-center">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="saveCurrency"
            >
              {{ $t("Common.Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import MasterServices from "@/apiServices/MasterServices";

export default {
  name: "form",
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      countryLists: [],
      currency_id: null,
      form: {
        currency_name: "",
        currency_code: "",
        currency_country_id: "",
        currency_is_active: true,
      },
      required,
    };
  },
  methods: {
    saveCurrency() {
      this.$refs.currencyMasterValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.form.currency_id = this.currency_id;
            // console.log("this.form=>",this.form);return false;
            const currencyMaster = await MasterServices.saveCurrency(this.form);

            if (currencyMaster.data.status) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title:
                    currencyMaster.data.message || "Currency Added Successfull",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
              this.$router.replace("/masters/currency-list");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: currencyMaster.data.message || "Cannot Added Currency",
                  icon: "EditIcon",
                  variant: "failure",
                },
              });
            }
          } catch (error) {
            console.log("Error in saveCurrency ", error);
            console.log({ error });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || "Error in saveCurrency",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }
      });
    },
    async getSingleCurrency(currency_id) {
      try {
        const response = await MasterServices.getSingleCurrency(currency_id);
        let singleCurrency = response.data.data;
        if (response.data.status) {
          this.form = {
            currency_name: singleCurrency.currency_name,
            currency_code: singleCurrency.currency_code,
            currency_country_id: singleCurrency.currency_country_id,
            currency_is_active: singleCurrency.currency_is_active,
          };
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },
  },
  beforeMount() {
    // console.log(this.$route.params);
    this.$store.dispatch("masters/setCountry");
    if (
      this.$route.params.currency_id &&
      !isNaN(this.$route.params.currency_id)
    ) {
      this.currency_id = this.$route.params.currency_id;
      this.getSingleCurrency(this.currency_id);
    }
  },
};
</script>

<style></style>
